@import 'assets/styles/variables';
@import 'assets/styles/mixins/scrollbar';

.chart {
  &__header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 0 15px 0;
  }

  &__headerTop {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__headerBottom {
    // Put your styles
  }

  &__headerTitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
  }

  &__body {
    display: flex;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    &_xScrollable {
      @include scrollbar(12px, 4px, #596271, transparent);
      overflow-x: auto;
    }

    &_yScrollable {
      @include scrollbar(12px, 4px, #596271, transparent);
      overflow-y: auto;
    }
  }

  &__footer {
    margin: 10px 0 0 0;
  }

  &__offsetChangerWrap {
    max-width: 220px;
    margin: 0 auto;
    transition: max-width 0.2s ease-out;
  }
}
