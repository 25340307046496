.modal {
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#201804, 0.5);
    z-index: 999;
  }

  &-window {
    max-width: 80%;
    max-height: 90vh;
    overflow: auto;
    width: 470px;
    background-color: $light;
    border-radius: 10px;
    padding: 56px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    text-align: center;
    h4 {
      font-family: Lato;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
    }
  }

  &-close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    z-index: 5;
    background-color: transparent;
    color: $dark;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  &-actions {
    margin: 32px auto 0;
    display: flex;
    align-items: center;
    max-width: 500px;

    justify-content: space-between;
    .btn {
      margin: 0 7px;
    }
    &--single {
      width: 314px;
    }
    &--fullsize {
      margin: 32px -7px 0;
      width: calc(100% + 14px);
    }
  }
}
