@import '../../../assets/styles/variables';

.changer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 24px;

  &__item {
    padding: 0 0 8px 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 16px;
    color: $accent;
    cursor: pointer;

    &_active {
      font-weight: 700;
      color: $accent2;
      cursor: default;
      border-bottom: 2px solid $accent2;
    }
  }
}
