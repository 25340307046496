.ant-btn {
  $btn: &;
  border-radius: 3px;

  &-loading-icon {
    margin-right: 5px;
  }

  &-lg {
    padding: 10px 35px;
    height: 36px;
    font-weight: 600;
    font-size: 15px;
    line-height: 1;
    letter-spacing: -0.24px;
    border-radius: 8px;

    #{$btn}-loading-icon {
      margin-right: 10px;
    }
  }
}
