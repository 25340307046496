@import '../../../assets/styles/variables';

.dashboardChartWrap {
  padding: 32px 24px 24px 24px;
  border: 1px solid $border;
  border-radius: 6px;
  background-color: $light;

  &--secondary {
    background-color: $secondary-1;
    border-color: $secondary-1;
  }

  &--fullHeight {
    height: 100%;
  }
}
