.errorList {
  position: absolute;
  margin: 3px 0;
  &--onRequest {
    margin: 14px 24px;
  }
}

.errorItem {
  @extend .label-text;
  display: flex;
  align-items: center;
  color: $error;
}

.errorItem-icon {
  margin-right: 4px;
}
