@import '../mixins/scrollbar';

$table-border-color: #cad2de;

.ant-table-wrapper {
  height: calc(100% - 68px);

  .ant-spin-nested-loading {
    height: 100%;

    .ant-spin-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .ant-table {
    overflow: auto;
    border: 1px solid $table-border-color;
    border-radius: 7px;
    @include scrollbar(12px, 3px, #596271, transparent, $table-border-color);

    &.ant-table-fixed-header {
      overflow-y: hidden;

      .ant-table-body {
        @include scrollbar(12px, 3px, #596271, transparent, $table-border-color);
        overflow-y: auto !important;
      }

      .ant-table-container {
        min-width: 700px;
      }
    }

    tr.drop-over-downward td {
      border-bottom: 2px dashed $primary;
    }

    tr.drop-over-upward td {
      border-top: 2px dashed $primary;
    }
  }

  .ant-table-thead {
    position: sticky;
    top: 0;
    z-index: 100;

    .ant-table-cell {
      .ant-table-column-sorters {
        .ant-table-column-title {
          > div {
            > svg {
              flex-shrink: 0;
            }
          }
        }
      }
    }

    th.ant-table-column-has-sorters:hover {
      background: darken($secondary-1, 1);
    }
  }

  .ant-table-pagination {
    align-items: center;

    .ant-pagination-total-text {
      margin-right: auto;
    }
  }
}

.ant-pagination-item {
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 40px;
  min-width: 40px;
  line-height: 40px;
}

.ant-pagination-item-active {
  background-color: $accent;

  a {
    display: block;
    color: $light;
  }

  &:hover a {
    color: $light;
  }
}

.ant-pagination-prev,
.ant-pagination-next {
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 40px;
  min-width: 40px;
  line-height: 40px;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 5px;
}

.ant-table {
  @extend .caption-text;
}

.ant-table-thead {
  & > tr {
    & > th {
      font-weight: 600;
    }
  }
}

.ant-table-tbody {
  .ant-table-row {
    cursor: pointer;

    .ant-table-cell:first-child {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background-color: transparent;
        transition: 0.2s ease-in-out;
      }
    }

    &:hover {
      .ant-table-cell {
        background-color: rgba($accent, 0.1);

        &:first-child:before {
          background-color: $accent;
        }
      }
    }

    &:nth-child(even) {
      background-color: $secondary-1;
    }
  }
}

.ant-table-column-sorter {
  display: none;
}

.table-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -8px;
  .action-btn {
    width: 24px;
    height: 24px;
    padding: 2px;
    background: none;
    border: none;
    margin: 0 8px;
    cursor: pointer;
    color: $placeholder;
    transition: 0.2s ease-in-out;
    &:hover {
      color: $dark;
    }
    &--delete {
      &:hover {
        color: $error;
      }
    }
  }
}

.ant-table-filter-dropdown {
  .container {
    padding: 8px;
  }

  .ant-input-group {
    .ant-input-number-input {
      padding: 8px 10px;
      height: auto;
      color: #121212;
      font-size: 14px;
    }
  }

  .ant-picker-range {
    padding: 8px 10px;
    width: 100%;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    color: #121212;

    &.ant-picker-focused {
      border: 1px solid #40a9ff;
      box-shadow: 0 0 0 2px rgb(28 98 163 / 20%);
    }

    .ant-picker-input > input {
      font-size: 14px;
    }
  }
}
