.mainLayout {
  display: grid;
  grid-template-rows: 70px 5fr;
  grid-template-columns: auto 5fr 1.8fr;
  grid-template-areas:
    'header header header'
    'aside main main';
  height: 100vh;
  header {
    grid-area: header;
    border-bottom: 1px solid #eeeef3;
  }
  main {
    grid-area: main;
    padding: 54px 32px 32px;
    max-width: 2500px;
    height: 100%;
    overflow: hidden;
  }
  aside {
    grid-area: aside;
  }
}
