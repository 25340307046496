.orderSummary {
  border-radius: 8px;
  padding: 24px;
  background-color: $secondary-2;
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
  &-info {
    padding: 12px 0;
    border-bottom: 1px solid $border;
  }
  &-main {
    @extend .body-large-text;
    padding: 12px 0;
  }
}
