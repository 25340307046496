.ant-select-dropdown {
  .ant-select-item {
    font-size: 14px;
  }

  .rc-virtual-list-scrollbar {
    width: 4px !important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $bg;

    .ant-select-item-option-state {
      color: $primary-color;
    }
  }
}
