@import 'assets/styles/mixins/scrollbar';

.field {
  margin-bottom: 16px;

  &--hidden {
    display: none;
  }
}

.field-header {
  @extend .label-text;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.field-caption {
  text-transform: lowercase;
}

.hooked-field {
  &--hiddenField {
    display: none;
  }

  &--withError {
    .ant-input,
    .ant-input-password,
    .field-wrapper input,
    .field-wrapper .ant-input,
    .field-wrapper .ant-input-number,
    .field-wrapper .ant-input-number-affix-wrapper,
    .field-wrapper .ant-input-number-group-wrapper .ant-input-number-group-addon:last-child,
    .field-wrapper .ant-picker,
    .field-wrapper .ant-select .ant-select-selector {
      border-color: $error;
    }
    .errorList {
      position: absolute;
    }
  }
}

.ant-picker-time-panel-column {
  @include scrollbar(7px, 2px, #b1b6c4, transparent, transparent);
}

.field-wrapper {
  position: relative;
  input {
    width: 100%;
    border-radius: 8px;
    border: 1px solid $placeholder;
    color: $dark;
    font-size: 17px;
    padding: 11px 12px;
    line-height: 22px;
    transition: 0.2s ease-in-out;
    &::placeholder {
      color: $placeholder;
    }
    &:hover {
      border-color: $hover;
    }
    &:focus-visible {
      outline: none;
    }
    &:focus {
      box-shadow: none;
      border-color: $dark;
      outline: 0;
    }
  }
  .ant-input {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #808898;
    color: $dark;
    font-size: 17px;
    padding: 11px 12px;
  }
  .ant-input-password {
    padding: 0;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #808898;
    input {
      padding: 11px 12px;
    }
    .ant-input-suffix {
      position: absolute;
      top: 50%;
      right: 11px;
      transform: translateY(-50%);
    }
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none;
    border-color: $dark;
  }
  .ant-input-number {
    display: block;
    width: 100%;
    padding: 9px 12px;
    border-radius: 8px;
    border: 1px solid #808898;
    overflow: hidden;

    input {
      border: none;
      padding: 0;
    }
    &:not(.ant-input-number-disabled):hover {
      border-color: $hover;
    }
    &-focused {
      box-shadow: none;
      border-color: $dark;
    }
  }
  .ant-input-number-affix-wrapper {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #808898;

    .ant-input-number-prefix {
      font-size: 17px;
      line-height: 17px;
    }

    .ant-input-number {
      padding: 9px 12px 9px 5px;
    }

    &-focused {
      box-shadow: none;
      border-color: $dark;
    }

    &-disabled {
      border: 1px solid #ededef;
      background-color: #ededef;

      &:hover {
        border: 1px solid #ededef;
      }
    }

    &::before {
      display: none;
    }
  }
  .ant-input-number-group-wrapper {
    width: 100%;

    .ant-input-number-group-addon:last-child {
      border-color: #808898;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  .ant-picker {
    width: 100%;
    border-radius: 8px;
    border: 1px solid $placeholder;
    color: $dark;
    font-size: 17px;
    padding: 11px 12px;

    &-input input {
      padding: 0;
      border-radius: 0;
      border: none;
    }

    &:not(.ant-picker-disabled):hover {
      border-color: $hover;
    }

    &-focused {
      box-shadow: none;
      border-color: $dark;
    }

    &.ant-picker-range {
      .ant-picker-input {
        > input {
          font-size: 16px;
        }
      }
    }
  }
  .ant-select {
    @extend .body-text;
    letter-spacing: initial;
    width: 100%;

    &:hover {
      .ant-select-selector {
        border-color: #808898;
      }
    }

    .ant-select-selection-placeholder {
      color: $placeholder;
    }

    .ant-select-selector {
      height: 46px;
      border-radius: 8px;
      border-color: $placeholder;

      .ant-select-selection-item,
      .ant-select-selection-search {
        display: flex;
        align-items: center;
      }

      .ant-select-selection-placeholder {
        line-height: 44px;
      }
    }

    &.ant-select-multiple.ant-select-show-search {
      .ant-select-selector {
        min-height: 46px;
        height: auto;
      }

      .ant-select-selection-item {
        font-size: 14px;
        line-height: 16px;
        background: #e7e7e7;
        border: 1px solid #dcdcdc;
      }
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        box-shadow: none;
        border-color: $dark;
      }
    }
  }

  textarea {
    color: $dark;
    &::placeholder {
      color: $placeholder;
    }
    &:focus {
      box-shadow: none;
      border-color: $dark;
    }
  }
  .ant-input[disabled],
  .ant-picker-disabled,
  .ant-input-number-disabled,
  input[disabled],
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: $placeholder;
    border-color: $disabled;
    background-color: $disabled;
    cursor: not-allowed;
  }
  .errorList {
    top: 100%;
  }
}
