@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;500;600;700&display=swap');

h2,
.h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.21;
}

h3,
.h3 {
  font-weight: 700;
  font-size: 22px;
  line-height: 1.27;
  letter-spacing: 0.35px;
}

.body-text {
  font-weight: 400;
  font-size: 17px;
  line-height: 1.29;
  letter-spacing: -0.41px;
}

.body-large-text {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.25;
  letter-spacing: 0.38px;
}

.button-text {
  font-weight: 600;
  font-size: 15px;
  line-height: 1;
  letter-spacing: -0.24px;
}

.button-large-text {
  font-weight: 600;
  font-size: 17px;
  line-height: 1;
  letter-spacing: -0.41px;
}

.caption-text {
  font-weight: 400;
  font-size: 13px;
  line-height: 1.38;
  letter-spacing: -0.08px;
}

.label-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 1.2;
  letter-spacing: 0.16px;
}
