.header {
  background-color: #fff;
  .wrap {
    max-width: 100%;
    padding: 0 32px 0 50px;
    height: 100%;
    @include flex($row, $btwen, $center);
    .member {
      @include flex($row, $center, $center);
      column-gap: 12px;
      color: #000000;

      .imageWrap {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        overflow: hidden;

        .image {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }

      p {
        margin-left: 5px;
      }
    }
  }
}
