.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: none;
  border-radius: $radius;
  min-width: 160px;
  &--primary {
    background-color: $accent;
    color: $light;
    &:hover,
    &:focus {
      background-color: rgba($accent, 0.9);
    }
    &[disabled] {
      background-color: $placeholder;
      cursor: not-allowed;
    }
  }
  &--danger {
    background-color: $danger;
    color: $light;
    &:hover {
      background-color: rgba($danger, 0.9);
    }
    &[disabled] {
      background-color: rgba($danger, 0.5);
      cursor: not-allowed;
    }
  }
  &--simple {
    background-color: transparent;
    &:hover {
      background-color: $light;
    }
    &[disabled] {
      color: $placeholder;
      cursor: not-allowed;
    }
  }
  &--large {
    @extend .button-large-text;
    padding: 16px;
    height: 50px;
  }
  &--normal {
    @extend .button-text;
    padding: 8px 24px;
    height: 36px;
  }
  &--small {
    @extend .button-text;
    padding: 8px 15px;
    min-width: unset;
  }
  svg:first-child {
    margin-right: 8px;
  }
  svg:last-child {
    margin-left: 8px;
  }
}
