
.cashbackRow {
  margin-bottom: 24px;
}
.cashbackInput {
  width: 100% !important;
  height: 46px !important;
  border-radius: 8px;
}

.actions {
  padding-top: 24px;
}