.rolesWrapper {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }


  &__rolesTitle {
    margin-bottom: 15px;
  }

  &__btnTextContainer {
    display: flex;
    align-items: center;
  }

  &__roleContainer {
    border: 1px solid #bfc7d0;
    margin-bottom: 30px;
    border-radius: 8px;

    width: 90%;

    &__head {
      font-size: 16px;
      font-weight: 700;
      min-height: 36px;
      margin-bottom: -1px;
      padding: 0 12px;

      &__wrapper {
        display: flex;
        align-items: center;
      }

      &__title {
        display: inline-block;
        flex: 1 1;
        padding: 8.5px 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &__extra {
        margin-left: auto;
        padding: 8.5px 0;
        color: rgba(0, 0, 0, 0.85);
        font-weight: normal;
        font-size: 12px;
      }

    }

    &__body {
      padding: 12px;
      font-size: 13px;
    }
  }



  &__permissionsContainer {
    &__cardsContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;


      .ant-card-body {
        display: flex !important;
      }
    }
  }
}

.selected {
  background-color: #fff;
  border: 1px solid #808898;
}
