@import 'assets/styles/variables';

.ant-card {
  font-size: 13px;
  border-radius: 7px;

  &-bordered {
    border: 1px solid $border;
  }
}

.ant-card-head {
  font-size: 16px;
  font-weight: 700;
}

.ant-card-actions {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;

  > li {
    > span {
      font-size: 13px;
    }
  }
}
