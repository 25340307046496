@import 'assets/styles/mixins/scrollbar';

.table {
  width: 100%;

  table {
    width: 100%;

    thead {
      > tr {
        > th {
          padding: 5px 5px 13px 5px;
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          background: #ffffff;

          &:nth-child(1) {
            text-align: left;
          }

          &:nth-child(2) {
            text-align: left;
          }

          &:nth-child(3) {
            text-align: center;
          }
        }
      }
    }

    tbody {
      > tr {
        > td {
          padding: 12px 5px;
          background: #f2f2f2;

          &:nth-child(1) {
            width: 5%;
            font-weight: 600;
            vertical-align: middle;
          }

          &:nth-child(2) {
            width: 60%;
          }

          &:nth-child(3) {
            width: 35%;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
            vertical-align: middle;
          }
        }

        &:hover {
          > td {
            background: #e5e5e5;
          }
        }
      }
    }
  }

  &_stickyHead {
    @include scrollbar(12px, 4px, #596271, transparent);
    overflow-y: auto;

    table {
      thead {
        > tr {
          > th {
            position: sticky;
            top: 0;
          }
        }
      }
    }
  }
}
