.productItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;

  &:not(:last-child) {
    margin-bottom: 20px;
    border-bottom: 1px solid $border;
  }
  &-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    min-width: 64px;
    padding: 4px;
    margin-right: 12px;

    border-radius: 4px;
    overflow: hidden;
    background-color: $secondary-2;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-meta {
    margin-left: auto;
  }
  &-title {
    margin-bottom: 8px;
    color: $primary;
  }
  &-price {
    color: $placeholder;
    margin-bottom: 8px;
  }
  &-quantity {
    color: $placeholder;
  }
}
