@import 'assets/styles/variables';
@import 'src/assets/styles/mixins/scrollbar';

.dashboard {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 0 5px 0;
    gap: 15px;
  }

  &__datesPicker {
    max-width: 243px;
  }

  &__chartItem {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__chartItem-titleWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 0 28px 0;
    gap: 20px 15px;
  }

  &__chartItem-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
  }

  &__chartItem-xScrollWrap {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    @include scrollbar(12px, 3px, #596271, transparent, $border);
  }

  &__textRowWrap {
    display: flex;

    &_leftAlign {
      justify-content: flex-end;
    }
  }

  &__fatText {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
  }

  &__noDataWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
  }

  &__noDataText {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    color: $secondary-5;
  }

  .ordersChart {
    min-height: 320px;
    max-height: 320px;
  }

  .ordersAverageChart {
    min-height: 320px;
    max-height: 320px;
  }

  .ordersPerAreaChart {
    min-height: 320px;
    max-height: 320px;
  }

  .ordersPerAreaChart_horizontalScroll {
    min-height: auto;
    max-height: none;
  }

  .ordersAveragePerAreaChart {
    min-height: 320px;
    max-height: 320px;
  }

  .ordersAveragePerAreaChart_horizontalScroll {
    min-height: auto;
    max-height: none;
  }

  .KNetVsCashChart {
    min-height: 320px;
    max-height: 320px;
  }

  .KNetVsCashChart_horizontalScroll {
    min-height: auto;
    max-height: none;
  }
}
