.userNotificationForm {
  .main-fields-grid {
    margin-top: 20px;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    grid-template-areas:
      'id type'
      'title body'
      'relation body'
      'submit body';

    &__item {
      &--id {
        grid-area: id;
      }

      &--type {
        grid-area: type;
      }

      &--title {
        grid-area: title;
      }

      &--body {
        grid-area: body;
      }

      &--relation {
        grid-area: relation;
      }

      &--submit {
        grid-area: submit;
      }
    }
  }

  button[type='submit'] {
    width: 100%;
    margin-bottom: 16px;
  }

  &--newRecord {
    .main-fields-grid {
      grid-template-areas:
        'title body'
        'type body'
        'relation body'
        'submit body';
    }
  }
}

@media all and (max-width: 1000px) {
  .userNotificationForm {
    .main-fields-grid {
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-column-gap: 0;
      grid-template-areas:
        'id'
        'type'
        'title'
        'body'
        'relation'
        'submit';
    }
  }
}
