@import 'assets/styles/variables';

.deletePromocodeIconWrap {
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deletePromocodeIcon {
  color: $border !important;
  transition: color 0.25s ease-out;

  &:hover {
    color: $error !important;
  }
}
