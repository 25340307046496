.ant-picker.ant-picker-range {
  padding: 8px 10px;
  width: 100%;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  color: #121212;

  &.ant-picker-focused {
    border: 1px solid #40a9ff;
    box-shadow: 0 0 0 2px rgb(28 98 163 / 20%);
  }

  .ant-picker-input > input {
    font-size: 14px;
  }
}
