.notFound {
  &-btn {
    margin-top: 32px;
  }
}

.serviceText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 30px;
  color: #101d37;
  h3 {
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    color: inherit;
  }
  p {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    margin-top: 16px;
  }
}
