@import 'assets/styles/variables';


.actionsRow {
  display: inline-flex;
  align-items: center;
  gap: 7px;
}

.deleteCampaignIconWrap {
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.isActiveCampaignIcon {
  color: $placeholder !important;
  transition: color 0.25s ease-out;
  cursor: pointer;

  &:hover {
    color: $accent !important;
  }
}

.deleteCampaignIcon {
  color: $placeholder !important;
  transition: color 0.25s ease-out;
  cursor: pointer;

  &:hover {
    color: $error !important;
  }
}