@import 'assets/styles/variables';

.flexBox {
  display: flex;
  gap: 40px 25px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;

  &__row {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__rowTitle {
    // Put your styles
  }

  &__rowBody {
    // Put your styles
  }
}

.text {
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: $primary-color;

  &_grayTitle {
    color: #91969b;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.01em;
  }

  &_fat {
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
  }

  &_green {
    color: $success;
  }

  &_red {
    color: $danger;
  }
}

.sumStatsRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
