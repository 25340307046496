.tableHeader {
  padding: 20px 0;
  max-width: 500px;
  .tableHeader__button {
    font-weight: normal !important;

    &:focus {
      border: 1px solid gray !important;
    }
    &:active {
      border: 1px solid gray !important;
    }
    &:hover {
      border: 1px solid gray !important;
      transition: border 0.5s;
    }
  }


  .tableHeader__button_active {
    border: 1px solid gray !important;
  }
}

