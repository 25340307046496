.flyerUploader {
  &:global(.ant-upload.ant-upload-drag) {
    height: 300px;
    border: 1px dashed #000000;

    &:not(.ant-upload-disabled):hover {
      border: 1px dashed #000000;
    }

    &:global(.ant-upload-disabled) {
      background: #ededef;
      border: 1px dashed #d7d7d7;
    }

    &:global(.ant-upload-disabled):hover {
      border: 1px dashed #d7d7d7;
    }
  }

  &_hasError {
    &:global(.ant-upload.ant-upload-drag) {
      border: 1px dashed #db0000;

      &:not(.ant-upload-disabled):hover {
        border: 1px dashed #db0000;
      }
    }

    :global(.ant-upload-text),
    :global(.ant-upload-hint) {
      color: #db0000 !important;
    }
  }

  .imageHolder {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    &__img {
      display: block;
      object-fit: cover;
      object-position: center center;
      max-width: 100%;
      min-height: 100%;
    }

    &__deleteOverlay {
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
      transition: opacity 0.2s;
      z-index: 1;

      &:hover {
        opacity: 1;
      }
    }

    &__deleteBtn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;

      &:global(.ant-btn-icon-only.ant-btn-lg) {
        width: 41px;
        height: 41px;
      }

      &:global(.ant-btn.ant-btn-circle) {
        border-radius: 50%;
      }
    }
  }
}
