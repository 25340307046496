@import 'signIn';
@import 'src/assets/styles/mixins/scrollbar';

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  &-title {
    display: flex;
    align-items: center;
    svg {
      margin-right: 12px;
      transition: 0.2s ease-in-out;
      cursor: pointer;
    }
    &:hover {
      svg {
        transform: translateX(-4px);
      }
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    &-actions {
      display: flex;
      align-items: center;
      .btn {
        margin-left: 12px;
      }
    }
  }
  &-wrapper {
    position: relative;
    background-color: $light;
    border: 1px solid $border;
    padding: 40px;
    border-radius: $radius;
    flex-grow: 1;
    overflow-y: auto;
    @include scrollbar(12px, 3px, #596271, transparent, $border);
  }
}
