.tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;

  .tab {
    &-link {
      @extend .button-text;
      display: block;
      padding: 12px 32px;
      border-radius: 6px;
      color: $dark;
      border: 1px solid transparent;
      transition: 0.2s ease-in-out;

      &:hover {
        color: $accent2;
      }

      &.tab-link--selected {
        color: $accent2;
        background-color: $light;
        border-color: $border;
      }
    }
  }
}
