.preloaderBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &--overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    z-index: 100;
  }

  &--small {
    .ant-spin {
      .ant-spin-dot-item {
        width: 9px;
        height: 9px;
      }

      .ant-spin-dot {
        font-size: 20px;
      }
    }
  }

  &--normal {
    .ant-spin {
      .ant-spin-dot-item {
        width: 20px;
        height: 20px;
      }

      .ant-spin-dot {
        font-size: 42px;
      }
    }
  }

  &--large {
    .ant-spin {
      .ant-spin-dot-item {
        width: 32px;
        height: 32px;
      }

      .ant-spin-dot {
        font-size: 70px;
      }
    }
  }
}
