@import 'assets/styles/mixins/scrollbar';

.scrolledLayout {
  display: grid;
  grid-template-rows: 70px 5fr;
  grid-template-columns: auto 5fr 1.8fr;
  grid-template-areas:
    'header header header'
    'aside main main';
  height: 100vh;

  header {
    grid-area: header;
    border-bottom: 1px solid #eeeef3;
  }

  main {
    grid-area: main;
    height: 100%;
    overflow: hidden;

    .container {
      padding: 54px 32px 32px;
      width: 100%;
      overflow-y: auto;
      @include scrollbar(12px, 3px, #596271, transparent);

      &__inner {
        max-width: 2500px;
      }
    }
  }

  aside {
    grid-area: aside;
  }
}
