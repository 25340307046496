.campaignForm {

  .actions {
    padding-top: 24px;
  }
  .saveBtn,
  .deleteBtn {
    width: 100%;
  }

  :global {
    .form-visibility {
      width: 100%;
      margin-top: 0;

      @media (min-width: 768px) {
        margin-top: 13px;
      }
    }
  }
}