.searchBar {
  display: flex;
  align-items: center;
  border-radius: $radius;
  background-color: rgba(36, 36, 56, 0.05);
  height: 36px;
  padding: 8px;
  &-input {
    border: none;
    background-color: transparent;
    height: 100%;
    color: $dark;
    &::placeholder {
      color: $placeholder;
    }
    &:focus {
      outline: none;
    }
  }
  &-label {
    margin-right: 8px;
  }
}
