/*FONTS*/

$font-family: 'Lato', 'Arial', 'sans-serif';
$font-size-base: '16px';

/*COLORS*/

$dark: #121212;
$light: #ffffff;
$bg: #e5e5e5;

$primary-color: #101d37;

$secondary-1: #f7f8fa;
$secondary-2: #f4f4f5;
$secondary-3: #f2f3f5;
$secondary-4: #f2f2f2;
$secondary-5: #c2c2c2;

$placeholder: #808898;
$border: #d4d4d4;

$primary: #007aff;
$disabled: #ededef;
$hover: #1f2d45;
$mediumBlue: #4c5973;

$accent: #101d37;
$accent2: #34558b;
$success: #27ae60;
$danger: #ff4d4f;
$error: #db0000;

/*OTHER*/

$shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
$radius: 8px;
