@import 'assets/styles/variables';

.ordersPerAreaChart {
  &__noData {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 320px;
  }

  &__noDataText {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    color: $secondary-5;
  }
}
