@mixin scrollbar(
  $size: 12px,
  $offset: 3px,
  $scrollbar-color: #596271,
  $background-color: #ffffff,
  $bordered-left-color: null
) {
  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: $size;
    height: $size;

    @if $bordered-left-color {
      border-left: 1px solid $bordered-left-color;
    }
  }

  &::-webkit-scrollbar-thumb {
    border: $offset solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: $scrollbar-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // Standard version (Firefox only for now)
  scrollbar-color: $scrollbar-color $background-color;
}
