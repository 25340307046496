.authLayout {
  width: 100%;
  height: 100vh;
  position: relative;
  &-wrapper {
    position: absolute;
    top: 15%;
    bottom: 15%;
    left: 20%;
    right: 20%;
    background-color: $light;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: $shadow;
    border-radius: $radius;
    border: 1px solid $border;
    padding-bottom: 5%;
  }
  &-logo {
    margin-bottom: 10%;
  }
  &-title {
    margin-bottom: 18px;
    text-align: center;
  }
}

@media only screen and (max-width: 1600px) {
  .authLayout {
    &-logo {
      margin-bottom: 5%;
    }
    &-wrapper {
      top: 10%;
      bottom: 10%;
    }
  }
}
