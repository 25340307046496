@import '../../../assets/styles/variables';

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 24px;
  background: $secondary-4;
  border: 1px solid $secondary-5;
  color: $dark;
  border-radius: 6px;

  &__titleWrap {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: inherit;
    letter-spacing: normal;
    text-align: right;

    &_fat {
      font-weight: 600;
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.02em;
    }
  }

  &__hint {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: inherit;
  }

  &__valueWrap {
    display: flex;
    justify-content: flex-end;
  }

  &__value {
    margin: 10px 0 0 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: $accent;
    letter-spacing: 0.1em;
    text-align: right;

    &_fat {
      margin: 8px 0 0 0;
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: normal;
    }
  }

  &_mediumBlue {
    background: $mediumBlue;
    border: 1px solid $mediumBlue;
    color: $light;
  }

  &_mediumBlue &__value {
    color: $light;
  }

  &_titleOneLine &__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
