.modal {
  &__title {
    font-size: 18px;
  }

  &__body {
    margin: 25px 0 0 0;
    font-size: 16px;
  }
}
