.form {
  &-fieldset {
    padding-bottom: 48px;
    &:not(:last-of-type) {
      border-bottom: 1px solid $border;
      margin-bottom: 32px;
    }
  }
  &-title {
    @extend .body-large-text;
    margin-bottom: 24px;
  }
  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    align-items: flex-start;
    &--delivery {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      .hooked-field:first-child {
        grid-area: 1 / 1 / 2 / 2;
      }
      .hooked-field:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
      }
      .hooked-field:last-child {
        grid-area: 1 / 2 / 3 / 3;
      }
    }
  }
  &-block {
    display: flex;
  }
  &-imgWrapper {
    padding-right: 32px;
    margin-right: 32px;
    border-right: 1px solid $border;
    width: 180px;
    height: 180px;
    min-width: 180px;
    box-sizing: content-box;
  }
  &-meta {
    .form-title {
      margin-bottom: 12px;
    }
    .form-caption {
      @extend .caption-text;
      margin-bottom: 4px;
      color: $placeholder;
    }
  }
  &-visibility {
    display: flex;
    align-items: center;
    padding: 14px;
    border-radius: 8px;
    background: rgba(36, 36, 56, 0.05);
    width: 276px;
    &-icon {
      min-width: 24px;
      color: $accent;
      margin-right: 12px;
    }
    &-text {
      color: $placeholder;
    }
    &-switch {
      margin-left: auto;
    }
  }
}

@import 'customer';
