:global(.field-wrapper) {
  .headerSelect:global(.ant-select) {
    width: 120px;

    :global(.ant-select-selector) {
      height: 30px;
      border-color: transparent !important;
    }
  }
}
