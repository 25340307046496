.reapop__notification {
  border: 1px solid $border !important;
  &-dismiss-icon {
    display: none !important;
  }
  &--success {
    border-left: 4px solid $success !important;
  }
  &--error {
    border-left: 4px solid $error !important;
  }
  &-meta {
    padding: 24px 24px 32px !important;
    text-align: center;
  }
  &-title {
    font-size: 22px !important;
    color: $dark !important;
    line-height: 1.27 !important;
    margin-bottom: 4px !important;
    font-weight: 700 !important;
  }
  &-message {
    font-size: 18px !important;
    color: $dark !important;
    line-height: 1.27 !important;
    font-weight: 700 !important;
    span {
      color: #34558b !important;
    }
  }
}
