.customer-form {
  .button-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    margin-top: 20px;
  }
}
.productItem {
  display: grid;
  grid-template-columns: repeat(2, 3fr) 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 9px;
  .btn--primary {
    min-width: 100px;
  }
}
