body {
  @extend .body-text;
  font-family: $font-family;
  min-width: 320px;
  overflow-x: hidden;
  color: $dark;
  background-color: $bg;
}

.container {
  height: 100%;
}

/*
.rtl{
  direction: rtl;
  unicode-bidi: bidi-override;
}*/
