@import 'assets/styles/variables';

.ant-modal {
  color: #121212;

  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-header {
    padding: 15px 20px;
    border-radius: 4px 4px 0 0;
  }

  .ant-modal-title {
    color: #232426;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.35px;
  }

  .ant-modal-body {
    padding: 16px 20px;
    font-size: 13px;
    line-height: 18px;
  }

  .ant-modal-footer {
    padding: 8px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
  }
}

// Buttons customize
.ant-modal {
  .ant-btn {
    border-radius: 4px;

    &-primary {
      color: $light;
      border-color: $primary-color;
      background: $primary-color;
    }
  }
}

// Inputs customize
.ant-modal {
  .ant-picker-range {
    padding: 8px 10px;
    width: 100%;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    color: #121212;

    &.ant-picker-focused {
      border: 1px solid #40a9ff;
      box-shadow: 0 0 0 2px rgb(28 98 163 / 20%);
    }

    .ant-picker-input > input {
      font-size: 14px;
    }
  }
}
