.avatarUploader {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;

  &-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(16, 29, 55, 0.6);
    transition: 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
  }

  &-imgWrapper {
    width: 180px;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &:hover {
    .avatarUploader-overlay {
      visibility: visible;
      opacity: 1;
    }
  }
}
