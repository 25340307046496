@import 'assets/styles/variables';

.actionIcon {
  color: $placeholder !important;
  transition: color 0.25s ease-out;
  cursor: pointer;

  &:hover {
    color: $accent !important;
  }

  &_delete {
    &:hover {
      color: $error !important;
    }
  }
}

.noDateString {
  display: inline-block;
  min-width: 100px;
  text-align: center;
}

.statusString {
  white-space: nowrap;
}

.actionsRow {
  display: inline-flex;
  align-items: center;
  gap: 7px;
}
