@import 'assets/styles/variables';

.block {
  width: 400px; // change analyticsBlockWidth param in Charts components accordingly this rule (width + marginRight)
  align-self: stretch;
  flex-shrink: 0;
  border-right: 1px solid $secondary-5;
  margin: 0 15px 0 0;

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__sector {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 20px 10px 10px;
    gap: 10px;
    border-bottom: 1px solid $secondary-5;
    flex-grow: 1;

    &:last-child {
      border-bottom: none;
    }
  }

  &__sectorRow {
    display: flex;
    align-items: center;
    gap: 10px;

    &_spaceBetween {
      justify-content: space-between;
    }
  }
}

.text {
  font-size: 16px;
  color: $primary-color;

  &_large {
    font-size: 18px;
  }

  &_larger {
    font-size: 20px;
  }

  &_largest {
    font-size: 22px;
  }

  &_fat {
    font-weight: 600;
  }

  &_upper {
    text-transform: uppercase;
  }

  &_gray {
    color: $placeholder;
  }

  &_green {
    color: $success;
  }

  &_red {
    color: $danger;
  }
}
