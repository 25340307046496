.promocode-form {
  &__divider {
    width: calc(100% + 80px);
    margin: 25px -40px;
    height: 1px;
    background: #d4d4d4;
  }

  .save-btn,
  .delete-btn {
    width: 100%;
  }

  &__code-type-changer {
    padding: 0 10px 0 5px;

    &-item {
      font-size: 17px;
      color: #808898;
      cursor: pointer;

      &--active {
        color: #007aff;
      }
    }
  }

  &__discountQuantityField {
    &--disabled {
      .field-wrapper {
        .ant-input-number-group-wrapper {
          .ant-input-number-group-addon {
            &:last-child {
              color: #808898;
              border-color: #ededef;
              background-color: #ededef;
              cursor: not-allowed;
            }

            .promocode-form__code-type-changer-item {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
}

.is-active-promocode-switch {
  .ant-switch {
    background: #d91f1f;

    .ant-switch-inner {
      margin: 0 7px 0 22px;
    }

    &.ant-switch-checked {
      background: #1890ff;

      .ant-switch-inner {
        margin: 0 22px 0 7px;
      }
    }
  }
}

.promocode-form-dropdown {
  .ant-select-item.ant-select-item-option[title='Everything'] {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 8px;
  }
}
