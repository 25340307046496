@import '../../../assets/styles/variables';

.changer {
  border: 1px solid black;
}

.navButton {
  &:global(.slick-prev),
  &:global(.slick-next) {
    width: 24px;
    height: 24px;
    margin-top: -12px;
  }

  &:disabled {
    cursor: default;
  }
}

.slide {
  text-align: center;

  &__text {
    font-size: 16px;
    line-height: 24px;
    color: $accent;
    white-space: nowrap;
  }

  &_disabled &__text {
    color: $secondary-5;
  }
}
