@import '../mixins/scrollbar';

$toggle-menu-fix-height: 40px;
$bottom-menu-fix-height: 97px;
$opened-menu-width: 345px;
$collapsed-menu-width: 80px;

.sidebar {
  background-color: #fff;
  display: flex;
  position: relative;
  height: calc(100vh - 70px);
  max-width: $opened-menu-width;
  width: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    background: #fff;
  }

  &__section {
    &_toggle-menu {
      width: $opened-menu-width;
      display: flex;
      align-items: center;
      height: $toggle-menu-fix-height;
      padding-left: 43px;
      overflow: hidden;
      border-bottom: 1px solid #eff0f2;
      border-right: 1px solid #f0f0f0;
      transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s, padding-left 0.05s ease-in;

      &_collapsed {
        width: $collapsed-menu-width;
        padding-left: 20px;
        transition: padding-left 0.2s ease-in;
      }
    }

    &_main-menu {
      width: $opened-menu-width;
      flex-grow: 1;
      max-height: calc(100% - #{$toggle-menu-fix-height} - #{$bottom-menu-fix-height});
      overflow-x: hidden;
      overflow-y: auto;
      @include scrollbar(12px, 3px, #596271, transparent);
      transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

      &_collapsed {
        width: $collapsed-menu-width;
        padding: 15px 0 0 0;
      }
    }

    &_bottom-menu {
      width: $opened-menu-width;
      padding: 20px 0 0 0;
      height: $bottom-menu-fix-height;
      overflow: hidden;
      border-top: 1px solid #eff0f2;
      transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

      &_collapsed {
        width: $collapsed-menu-width;
        padding: 0 0 10px 0;
        display: flex;
        align-items: flex-end;
      }
    }
  }

  .ant-menu {
    &.ant-menu-inline-collapsed {
      > .ant-menu-item {
        height: 50px;
        line-height: 50px;
        padding: 7px calc(50% - 13px) 0 calc(50% - 13px) !important;

        &.ant-menu-item-selected {
          background: #eaeaea;
        }
      }

      > .ant-menu-submenu {
        > .ant-menu-submenu-title {
          height: 50px;
          line-height: 50px;
          padding: 7px calc(50% - 13px) 0 calc(50% - 13px) !important;
        }
      }
    }
  }

  .ant-menu-inline {
    &.ant-menu-root {
      .ant-menu-item,
      .ant-menu-submenu-title {
        > .ant-menu-title-content {
          font-weight: 400;
          font-size: 20px;
          line-height: 25px;
          letter-spacing: 0.38px;
          color: #000;
        }

        &.ant-menu-item-danger {
          > .ant-menu-title-content {
            color: rgb(219, 0, 0);
          }
        }
      }

      .ant-menu-sub {
        > .ant-menu-item {
          height: 43px;
          padding-left: 80px !important;

          > .ant-menu-title-content {
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #000;
          }
        }
      }

      .ant-menu-item,
      .ant-menu-submenu-title {
        height: 56px;
      }

      &:not(.ant-menu-horizontal) {
        .ant-menu-item-selected,
        .ant-menu-item:hover {
          background-color: #eaeaea;

          > .ant-menu-title-content {
            color: #1890ff;
          }
        }
      }
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
      padding: 0 60px 0 50px;
      padding-left: 50px !important;
    }

    .ant-menu-item::after {
      border-right: 5px solid #101d37;
      right: auto;
      left: 0;
    }
  }

  .ant-menu-item {
    .ant-menu-item-icon + span {
      margin-left: 15px;
    }
  }

  .ant-menu-submenu-title {
    .ant-menu-item-icon + span {
      margin-left: 15px;
    }
  }
}

.ant-menu-submenu-popup {
  .ant-menu-vertical {
    > .ant-menu-item {
      height: 40px;
      line-height: 40px;
      font-size: 16px;

      > * {
        vertical-align: middle;
      }

      &.ant-menu-item-active,
      &.ant-menu-item-selected,
      &:hover {
        color: #000;
        background-color: #eaeaea;
      }
    }
  }
}
